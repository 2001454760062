
  import * as Sentry from "@sentry/react"

  Sentry.init({
    dsn: "https://bde7bf05d3c3a7e9ee30eb493145b521@o4507378228068352.ingest.de.sentry.io/4507531467554896",
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/staging.zero.giki.earth/, /^https:\/\/zero.giki.earth/],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    ignoreErrors: ['Non-Error promise rejection captured']
  })

